// import "@fontsource/poppins"
import './src/styles/global.css'
import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import '@fontsource/poppins/100.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

export const onClientEntry = () => {
	if (window?.location?.hostname !== 'localhost' && window?.location?.hostname.indexOf('vercel.app') === -1) {

		 // Create and Install Session Replay Plugin
		const sessionReplayTracking = sessionReplayPlugin({
			sampleRate: 0.05,
			serverZone: "EU",
		   });
		amplitude.add(sessionReplayTracking);

		amplitude.init('6703c5544703f86f85412218af52876f', {"serverZone":"EU","autocapture":{"elementInteractions":true}});
	}
}

export  const onRouteUpdate = ({ location, prevLocation }) => {
	const params = new URLSearchParams(location.search);
	const mailchimpUser = params.get('mc_u');
	const mailchimpCampaign = params.get('mc_c');
	if (mailchimpUser?.length > 0) {
		amplitude.setUserId(mailchimpUser);
	}
	if (mailchimpCampaign?.length > 0) {
		const identifyEvent = new amplitude.Identify();
		identifyEvent.setOnce('mailchimp-campaign', mailchimpCampaign); 
		amplitude.identify(identifyEvent);
	}
}

export const wrapRootElement = ({ element }) => {
	return (
	  <ParallaxProvider>
		{element}
	</ParallaxProvider>
	);
  }